import { GroupTypes } from './profile';
import { DateInUTC } from './types';

export type PromptConfigModel = {
  prompt?: string;
  prompt_identifier: string;
  prompt_text: string;
  model: string | OpenAIModelID;
  max_tokens: number;
  temperature: number;
  top_p: number;
  frequency_penalty: number;
  presence_penalty: number;
  best_of: number;
  stop?: string[];
};

export const DEFAULT_GO2BOT_TEMPERATURE = 0.6;

export type PromptConfigItemResponse = PromptConfigModel & {
  created: DateInUTC;
  modified: DateInUTC;
};

const DEFAULT_TOKEN_LIMIT_RATIO = 0.75;
const CHAR_LIMIT_TOKEN_RATIO_REDUCTION = 0.2;

const CHAR_LIMIT_TOKEN_RATIO =
  DEFAULT_TOKEN_LIMIT_RATIO - CHAR_LIMIT_TOKEN_RATIO_REDUCTION;

export interface OpenAIModel {
  id: string;
  name: string;
  alias: string;
  available: boolean;
  roles: GroupTypes[];
  restrict: GroupTypes[];
  source: 'openai' | 'anthropic' | 'vertex' | 'custom';
  tokenLimit: number;
  characterLimit: number;
  aliases: string[];
}

export enum OpenAIModelID {
  GPT_3_5 = 'gpt-3.5-turbo-16k',
  GPT_4 = 'gpt-4-0613',
  GPT_4_TURBO_PREVIEW = 'gpt-4-turbo-preview',
  GPT_4_VISION_PREVIEW = 'gpt-4-vision-preview',
  CLAUDE_2_1 = 'claude-2.1',
  CLAUDE_3_OPUS = 'claude-3-opus-20240229',
  CLAUDE_3_SONNET = 'claude-3-sonnet-20240229',
  CLAUDE_3_HAIKU = 'claude-3-haiku-20240307',
  GEMINI_PRO = 'gemini-1.0-pro',
  GEMINI_PRO_1_5 = 'gemini-1.5-pro-preview-0409',
  GEMINI_PRO_1_5_FLASH = 'gemini-1.5-flash-preview-0514',
}

enum OpenAIModelTokenLimit {
  GPT_3_5 = 16385,
  GPT_4 = 8192,
  GPT_4_TURBO_PREVIEW = 128000,
  GPT_4_VISION_PREVIEW = 128000,
  CLAUDE_2_1 = 200000,
  CLAUDE_3_OPUS = 180000,
  CLAUDE_3_SONNET = 180000,
  CLAUDE_3_HAIKU = 180000,
  GEMINI_PRO = 128000,
  GEMINI_PRO_1_5 = 1000000,
  GEMINI_PRO_1_5_FLASH = 1000000,
}

function calculateTokenCharLimit(limit: OpenAIModelTokenLimit) {
  return {
    tokenLimit: Math.floor(limit * DEFAULT_TOKEN_LIMIT_RATIO),
    characterLimit: Math.floor(limit * CHAR_LIMIT_TOKEN_RATIO),
  };
}

export const OpenAIModels: Record<OpenAIModelID, OpenAIModel> = {
  [OpenAIModelID.GPT_3_5]: {
    id: OpenAIModelID.GPT_3_5,
    name: 'Default (GPT-3.5)',
    alias: 'gpt-3.5-turbo',
    available: false,
    roles: [],
    restrict: [],
    source: 'openai',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.GPT_3_5),
    aliases: [],
  },
  [OpenAIModelID.GPT_4]: {
    id: OpenAIModelID.GPT_4,
    name: 'GPT-4',
    alias: 'gpt-4',
    available: false,
    roles: [],
    restrict: ['jobseekers'],
    source: 'openai',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.GPT_4),
    aliases: [],
  },
  [OpenAIModelID.GPT_4_TURBO_PREVIEW]: {
    id: OpenAIModelID.GPT_4_TURBO_PREVIEW,
    name: 'GPT-4 Turbo',
    alias: 'gpt-4-turbo-preview',
    available: false,
    roles: [],
    restrict: ['jobseekers'],
    source: 'openai',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.GPT_4_TURBO_PREVIEW),
    aliases: ['gpt-4-1106-preview'],
  },
  [OpenAIModelID.GPT_4_VISION_PREVIEW]: {
    id: OpenAIModelID.GPT_4_VISION_PREVIEW,
    name: 'GPT-4 Vision Preview',
    alias: 'gpt-4-vision-preview',
    available: false,
    roles: ['superuser', 'developer'],
    restrict: [],
    source: 'openai',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.GPT_4_VISION_PREVIEW),
    aliases: [],
  },
  [OpenAIModelID.CLAUDE_2_1]: {
    id: OpenAIModelID.CLAUDE_2_1,
    name: 'Claude 2.1 (200k)',
    alias: 'claude-2.1',
    available: true,
    roles: ['superuser', 'developer', 'claude_access'],
    restrict: [],
    source: 'anthropic',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.CLAUDE_2_1),
    aliases: [],
  },
  [OpenAIModelID.CLAUDE_3_OPUS]: {
    id: OpenAIModelID.CLAUDE_3_OPUS,
    name: 'Claude 3 Opus (180k)',
    alias: OpenAIModelID.CLAUDE_3_OPUS,
    available: true,
    roles: ['superuser', 'developer', 'claude_access'],
    restrict: [],
    source: 'anthropic',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.CLAUDE_3_OPUS),
    aliases: [],
  },
  [OpenAIModelID.CLAUDE_3_SONNET]: {
    id: OpenAIModelID.CLAUDE_3_SONNET,
    name: 'Claude 3 Sonnet (180k)',
    alias: OpenAIModelID.CLAUDE_3_SONNET,
    available: true,
    roles: ['superuser', 'developer', 'claude_access'],
    restrict: [],
    source: 'anthropic',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.CLAUDE_3_SONNET),
    aliases: [],
  },
  [OpenAIModelID.CLAUDE_3_HAIKU]: {
    id: OpenAIModelID.CLAUDE_3_HAIKU,
    name: 'Claude 3 Haiku (180k)',
    alias: OpenAIModelID.CLAUDE_3_HAIKU,
    available: true,
    roles: ['superuser', 'developer', 'claude_access'],
    restrict: [],
    source: 'anthropic',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.CLAUDE_3_HAIKU),
    aliases: [],
  },
  [OpenAIModelID.GEMINI_PRO]: {
    id: OpenAIModelID.GEMINI_PRO,
    name: 'Gemini Pro (32k)',
    alias: OpenAIModelID.GEMINI_PRO,
    available: true,
    // roles: [
    //   'superuser',
    //   'developer',
    //   'claude_access',
    //   'teammate',
    //   'internal',
    //   'it',
    // ],
    roles: ['superuser', 'developer', 'claude_access'],
    restrict: [],
    source: 'vertex',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.GEMINI_PRO),
    aliases: [],
  },
  [OpenAIModelID.GEMINI_PRO_1_5]: {
    id: OpenAIModelID.GEMINI_PRO_1_5,
    name: 'Gemini Pro (1M)',
    alias: OpenAIModelID.GEMINI_PRO_1_5,
    available: true,
    // roles: [
    //   'superuser',
    //   'developer',
    //   'claude_access',
    //   'teammate',
    //   'internal',
    //   'it',
    // ],
    roles: ['superuser', 'developer', 'claude_access'],
    restrict: [],
    source: 'vertex',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.GEMINI_PRO_1_5),
    aliases: [],
  },
  [OpenAIModelID.GEMINI_PRO_1_5_FLASH]: {
    id: OpenAIModelID.GEMINI_PRO_1_5_FLASH,
    name: 'Gemini Pro Flash (1M)',
    alias: OpenAIModelID.GEMINI_PRO_1_5_FLASH,
    available: true,
    // roles: [
    //   'superuser',
    //   'developer',
    //   'claude_access',
    //   'teammate',
    //   'internal',
    //   'it',
    // ],
    roles: ['superuser', 'developer', 'claude_access'],
    restrict: [],
    source: 'vertex',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.GEMINI_PRO_1_5_FLASH),
    aliases: [],
  },
};
